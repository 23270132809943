// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; // Import Footer
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Feedback from './pages/Feedback';
import CurrentActivities from './pages/CurrentActivities';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Donors from './pages/Donors';
import ICard from './components/ICard';
import Register from './components/Register';
import BreadcrumbsComponent from './components/BreadcrumbsComponent'; 

const App = () => {
  return (
    <>
      <Navbar />
      <BreadcrumbsComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/icard" element={<ICard />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/currentactivities" element={<CurrentActivities />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/donors" element={<Donors />} />
      </Routes>
      <Footer /> {/* Add Footer here */}
    </>
  );
};

export default App;

// src/components/Footer.js
import React from 'react';
import { AppBar, Typography, Toolbar, Box } from '@mui/material';

const Footer = () => {
  return (
    <>
      {/* Add a Box with spacing before the footer */}
      <Box style={{ marginTop: '20px' }} /> {/* Adjust the margin as needed */}
      <AppBar position="static" sx={{ backgroundColor: '#d1695c' }} style={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Typography variant="body1" style={{ flexGrow: 1, textAlign: 'center' }}>
            © 2024 Umesh Bhagvan Patil Badalpur. All rights reserved.
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Footer;

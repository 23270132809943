// src/api.js
import axios from 'axios';

const API = axios.create({
  baseURL: 'https://leva.yuvalink.com/api',
 //baseURL: 'http://localhost:5000/api/',
});

// Add a request interceptor to include the token
API.interceptors.request.use((req) => {
  const token = localStorage.getItem('token');
  if (token) {
    req.headers.Authorization = token;
  }
  return req;
});

export default API;

import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const BreadcrumbsComponent = () => {
  const location = useLocation();

  // Split the pathname into parts for breadcrumb links
  const pathnames = location.pathname.split('/').filter(x => x);

  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ margin: '16px 0' }}>
      <Link color="inherit" href="/" style={{ marginRight: '8px', marginLeft: '16px' }}>
        Home
      </Link>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const formattedName = name.charAt(0).toUpperCase() + name.slice(1); // Capitalize the first letter
        // Avoid duplicates by checking if the current path is the last one
        if (index === pathnames.length - 1) {
          return (
            <Typography color="textPrimary" key={routeTo}>
              {formattedName}
            </Typography>
          );
        }
        return (
          <Link color="inherit" key={routeTo} href={routeTo}>
            {formattedName}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;

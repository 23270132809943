import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <Container maxWidth="md" style={{ textAlign: 'center', marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Welcome to लेवा पाटीदार समाज मंडळ, बदलापुर
      </Typography>
      <Typography variant="h6" gutterBottom>
        Join us in our efforts to empower and uplift our community. We are dedicated to providing valuable services, organizing useful programs, and undertaking visionary projects for the betterment of all our members.
      </Typography>
      <Typography variant="h6" gutterBottom>
        आमच्या सर्व सदस्यांच्या हितासाठी मूल्यवान सेवा प्रदान करण्यासाठी, उपयुक्त कार्यक्रम आयोजित करण्यासाठी आणि दृष्टिकोनात्मक प्रकल्प राबवण्यासाठी समर्पित आहोत. आमच्या समुदायाच्या सक्षमीकरण आणि उन्नतीसाठी आमच्यासोबत सहभागी व्हा.
      </Typography>
      <Typography variant="body1" style={{ marginTop: '2rem', textAlign: 'left' }}>
        🙏🏻 लेवा पाटीदार समाज मंडळ, बदलापुर - महिला आयोजीत कार्यक्रम  
        दि. ०५/१०/२०२४ , शनिवार रोजी महीला मंडळाकडुन संध्याकाळी ६.०० ते ९.०० वा. पर्यंत (एक रात्र) "माता नवरात्री गरबा-भोंडला" कार्यक्रमाचे आयोजन करण्यात आले आहे, तरी आपल्या बदलापुर परिसरामधील सर्व लेवा महीला सखींना निमंत्रीत करण्यात येत आहे. सोबत मुलांना आणले तरी चालेल, येताना स्वतः दांडीया काठी व भोंडल्या साठी सुखा खाऊ प्रसाद सोबत आणावा.  
        धन्यवाद.  
        (स्थळ- आपल्या बदलापुर लेवा मंडळाच्या जागेवर, मांजर्ली, बदलापुर, पच्छिम)  
        अध्यक्ष
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        component={Link} 
        to="/icard" 
        style={{ marginTop: '1rem' }}
      >
        Confirm Your Availability
      </Button>
    </Container>
  );
};

export default Home;

// src/pages/ComingSoon.js
import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const ComingSoon = () => {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Coming Soon!
      </Typography>
      <Typography variant="body1" gutterBottom>
        This page is currently under construction. Please check back later!
      </Typography>
      <Button variant="outlined" component={Link} to="/" style={{ marginTop: '20px' }}>
        Go to Home
      </Button>
    </Container>
  );
};

export default ComingSoon;

import React from 'react';
import { Container, Typography } from '@mui/material';

const CurrentActivities = () => {
  return (
    <Container maxWidth="md" style={{ textAlign: 'center', marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Upcoming Event
      </Typography>
      <Typography variant="body1" style={{ textAlign: 'left', marginTop: '2rem' }}>
        लेवा पाटीदार समाज मंडळ, बदलापुर - महिला आयोजीत कार्यक्रम  
        दि. ०५/१०/२०२४ , शनिवार रोजी महीला मंडळाकडुन संध्याकाळी ६.०० ते ९.०० वा. पर्यंत (एक रात्र) "माता नवरात्री गरबा-भोंडला" कार्यक्रमाचे आयोजन करण्यात आले आहे, तरी आपल्या बदलापुर परिसरामधील सर्व लेवा महीला सखींना निमंत्रीत करण्यात येत आहे. 
        सोबत मुलांना आणले तरी चालेल, येताना स्वतः दांडीया काठी व भोंडल्या साठी सुखा खाऊ प्रसाद सोबत आणावा.  
        धन्यवाद.  
        (स्थळ- आपल्या बदलापुर लेवा मंडळाच्या जागेवर, मांजर्ली, बदलापुर, पच्छिम)  
        अध्यक्ष
      </Typography>
    </Container>
  );
};

export default CurrentActivities;

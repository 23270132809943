import React, { useState, useEffect } from 'react';
import API from '../api';
import {
  Button,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  CardMedia,
  Container,
  Box,
  Snackbar,
  Alert,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import Close Icon

const ICard = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [familyMembers, setFamilyMembers] = useState(1);
  const [photo, setPhoto] = useState(null);
  const [icards, setIcards] = useState([]);
  const [filteredIcards, setFilteredIcards] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // State for submit button visibility
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('mobile', mobile);
    formData.append('familyMembers', familyMembers);
    if (photo) {
      formData.append('photo', photo);
    }

    try {
      const response = await API.post('/icard/save-icard', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchIcards();
      resetForm();
      setIsSubmitted(true); // Set to true after successful submission
      setSnackbarMessage('Your data has been submitted successfully! Now search your mobile to download your ID card.');
      setOpenSnackbar(true); // Open the snackbar
    } catch (error) {
      console.error('Error saving I-Card:', error);
      setSnackbarMessage('Error submitting your data. Please try again.');
      setOpenSnackbar(true); // Open the snackbar in case of error
    }
  };

  const fetchIcards = async () => {
    try {
      const response = await API.get('/icard/get-icards');
      setIcards(response.data);
    } catch (error) {
      console.error('Error fetching I-Cards:', error);
    }
  };

  useEffect(() => {
    fetchIcards();
  }, []);

  const downloadIcard = async (id) => {
    try {
      const response = await API.get(`/icard/download/${id}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Icard_${id}.png`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading I-Card:', error);
    }
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setMobile('');
    setFamilyMembers(1);
    setPhoto(null);
    setIsSubmitted(false); // Reset the submit state
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term === '8080808080') {
      setFilteredIcards(icards);
    } else if (term.trim().length >= 6) {
      const filtered = icards.filter(
        (icard) =>
          icard.email.toLowerCase().includes(term.toLowerCase()) ||
          icard.mobile.includes(term)
      );
      setFilteredIcards(filtered);
    } else {
      setFilteredIcards([]);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="md">
      <Box py={4}>
        <Typography variant="h4" align="center" gutterBottom>
          लेवा पाटीदार समाज मंडळ, बदलापुर - महिला आयोजीत कार्यक्रम
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          दि. ०५/१०/२०२४, शनिवार रोजी महीला मंडळाकडुन संध्याकाळी ६.०० ते ९.०० वा. पर्यंत (एक रात्र)
          <br />
          "माता नवरात्री गरबा-भोंडला" कार्यक्रमाचे आयोजन करण्यात आले आहे.
          <br />
          (स्थळ- आपल्या बदलापुर लेवा मंडळाच्या जागेवर, मांजर्ली, बदलापुर, पच्छिम)
        </Typography>

        <Box my={3}>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Number of Family Members who attend this events"
              type="number"
              value={familyMembers}
              onChange={(e) => setFamilyMembers(e.target.value)}
              required
            />
            <Button
              variant="outlined"
              component="label"
              fullWidth
              sx={{ mt: 2 }}
            >
              Upload Photo
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setPhoto(e.target.files[0])}
                hidden
              />
            </Button>
            {!isSubmitted && ( // Conditionally render the button
              <Button fullWidth variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                Submit I-Card
              </Button>
            )}
          </form>
        </Box>

        <TextField
          fullWidth
          margin="normal"
          label="Search Mobile or Email and download your ID Card"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search Mobile or Email and download your ID Card"
        />

        <Typography variant="h5" align="center" gutterBottom>
          Search Mobile or Email and download your ID Card
        </Typography>

        <Typography variant="h6" align="center" gutterBottom>
          {filteredIcards.length > 0 ? `Total Records: ${filteredIcards.length}` : 'No records to display'}
        </Typography>

        {filteredIcards.length > 0 ? (
          <List>
            {filteredIcards.map((icard) => (
              <ListItem key={icard.id}>
                <Card sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 100 }}
                    image={`https://leva.yuvalink.com/uploads/${icard.photo}`}
                    alt={icard.name}
                  />
                  <CardContent>
                    <ListItemText
                      primary={icard.name}
                      secondary={`Email: ${icard.email} | Mobile: ${icard.mobile} | Family Members: ${icard.familyMembers}`}
                    />
                    <Button variant="outlined" color="secondary" onClick={() => downloadIcard(icard.id)}>
                      Download I-Card
                    </Button>
                  </CardContent>
                </Card>
              </ListItem>
            ))}
          </List>
        ) : (
          searchTerm && (
            <Typography align="center" color="textSecondary">
              No results found for "{searchTerm}"
            </Typography>
          )
        )}
      </Box>

      {/* Snackbar to show submission message in center */}
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }} // Centering the Snackbar
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ICard;

// src/components/Navbar.js
import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../logo.png'; // Adjust the path if necessary
import logo_1 from '../logo_1.png'; // Adjust the path if necessary

const Navbar = () => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const isLoggedIn = !!localStorage.getItem('token');

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'ID Card', path: '/icard' },
    { text: 'Register', path: '/register' },
    { text: 'About Us', path: '/about_us' },
    { text: 'Feedback', path: '/feedback' },
    { text: 'Activities', path: '/currentactivities' },
    { text: 'Gallery', path: '/gallery' },
    { text: 'Contact Us', path: '/contact' },
    { text: 'Login', path: '/login' },
    { text: 'DONORS', path: '/donors' },
  ];

  return (
    <AppBar position="static" sx={{ backgroundColor: '#d1695c' }}>
      <Toolbar>
        <img
          src={logo_1}
          alt="Logo"
          style={{ width: 50, height: 'auto', marginRight: '16px' }} // Adjust size as needed
        />
        <Typography variant="h6" component={Link} to="/" style={{ flexGrow: 1, color: 'inherit', textDecoration: 'none' }}>
          लेवा पाटीदार समाज मंडळ, बदलापुर
        </Typography>
        <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {menuItems.map((item) => (
            <ListItem button component={Link} to={item.path} key={item.text}>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
          {isLoggedIn && (
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItem>
          )}
        </List>
      </Drawer>
      
    </AppBar>
  );
};

export default Navbar;
